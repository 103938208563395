.csvUpload ul li {
  list-style: none;
  font-size: 12px;
  padding: 6px;
}

.csvUpload ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .csvUpload ul {
    flex-direction: column;
  }
}
